<template>
  <ItemWrapper>
    <v-row justify-start wrap>
      <v-col cols="12">
        <AppCard
          :title="`${$t('Time Report')} ${timeReport.name || ''}`"
          :prev-route="prevRoute"
          :loading="pending.getTimeReport"
        >
          <template #titleActions>
            <v-btn
              :to="{ name: 'timeReports-edit', params: { id: timeReport.id } }"
              color="success"
              fab
              small
              dark
              class="mr-2"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <v-row v-if="!pending.getTimeReport" justify-start wrap>
            <v-col cols="12" sm="12" md="5">
              <h3>{{ $t('Informations') }}</h3>
              <v-simple-table>
                <template #default>
                  <tbody>
                    <tr>
                      <td>Provider</td>
                      <td>
                        <template v-if="tenant_handle.organisation">
                          {{ tenant_handle.organisation }}
                        </template>
                        <template v-else>{{ tenant_handle.firstname }} {{ tenant_handle.lastname }}</template>
                      </td>
                    </tr>
                    <tr>
                      <td>Customer</td>
                      <td>
                        <template v-if="client_handle.organisation">
                          {{ client_handle.organisation }}
                        </template>
                        <template v-else>{{ client_handle.firstname }} {{ client_handle.lastname }}</template>
                      </td>
                    </tr>
                    <tr>
                      <td>Year</td>
                      <td>{{ timeReport.year }}</td>
                    </tr>
                    <tr>
                      <td>Month</td>
                      <td>{{ timeReport.month }}</td>
                    </tr>
                    <tr>
                      <td>Report</td>
                      <td>
                        <v-btn color="info" small :loading="pending.getTimeReportPdf" @click="showPdf">
                          <v-icon>mdi-file-pdf-box</v-icon>
                          Zeige PDF
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </AppCard>
      </v-col>

      <v-col v-if="Object.keys(timeReportTicketsWithTasks).length >= 1" cols="12">
        <AppCard :title="$t('Linked Tasks')">
          <div class="text-center d-flex pb-4">
            <v-btn small @click="openAllPanels">Open all</v-btn>
            <v-btn small class="ml-2" @click="closeAllPanels">Close all</v-btn>
          </div>
          <v-expansion-panels v-model="ticketPanels" multiple>
            <v-expansion-panel v-for="(ticket, key) in timeReportTicketsWithTasks" :key="ticket.id">
              <v-expansion-panel-header>
                <p class="h4 my-2">
                  {{ key === 'withOutTicket' ? 'Not assigned tasks' : `Ticket: ${ticket.subject}` }}
                  <v-chip class="ml-5" small color="primary">Tasks: {{ ticket.tasks.length }}</v-chip>
                  <v-chip v-if="getCountTicketTasks(ticket)" class="ml-5" small color="success">
                    Selected Tasks: {{ getCountTicketTasks(ticket) }}
                  </v-chip>
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="ticketTaskHeaders"
                  :items="ticket.tasks"
                  dense
                  item-key="id"
                  hide-default-footer
                >
                  <template #[`item.from_date`]="{ item }">
                    <span>{{ $moment(item.from_date).format('DD.MM.YYYY HH:mm') }}</span>
                  </template>

                  <template #[`item.to_date`]="{ item }">
                    <span>{{ $moment(item.to_date).format('DD.MM.YYYY HH:mm') }}</span>
                  </template>

                  <template #[`item.user`]="{ item }">
                    <span v-if="item.user">
                      {{ item.user.firstname }}
                      {{ item.user.lastname }}
                    </span>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </AppCard>
      </v-col>
    </v-row>

    <AppDialog :is-open="visiblePdf" max-width="800px" @onCloseDialog="visiblePdf = false">
      <template slot="title">View PDF</template>

      <v-responsive :aspect-ratio="210 / 297">
        <object
          :data="`data:application/pdf;base64,${timeReportPdf}`"
          type="application/pdf"
          width="100%"
          height="100%"
        />
      </v-responsive>
    </AppDialog>
  </ItemWrapper>
</template>

<script>
import { defineComponent, onBeforeUnmount, computed, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard.vue'
import ItemWrapper from '@/layouts/ItemWrapper.vue'
import AppDialog from '@/components/UI/AppDialog.vue'

export default defineComponent({
  name: 'ViewTimeReport',
  components: {
    AppCard,
    ItemWrapper,
    AppDialog,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const prevRoute = ref('/TimeReports')
    const visiblePdf = ref(false)

    const ticketTaskHeaders = [
      {
        sortable: false,
        text: 'Message',
        value: 'message',
        width: 250,
      },
      {
        sortable: false,
        text: 'From',
        value: 'from_date',
        width: 160,
      },
      {
        sortable: false,
        text: 'To',
        value: 'to_date',
        width: 160,
      },
      {
        sortable: false,
        text: 'User',
        value: 'user',
      },
    ]

    // store
    const pending = computed(_ => $store.state.timeReport.pending)
    const timeReport = computed(_ => $store.state.timeReport.timeReport)
    const timeReportPdf = computed(_ => $store.state.timeReport.timeReportPdf)
    const timeReportTicketsWithTasks = computed(_ => $store.getters['timeReport/timeReportTicketsWithTasks'])
    const getTimeReport = _ => $store.dispatch('timeReport/getTimeReport')
    const getTimeReportPdf = summaryId => $store.dispatch('timeReport/getTimeReportPdf', summaryId)
    const clearTicketSummary = _ => $store.commit('timeReport/CLEAR_TIME_REPORT')

    const tenant_handle = computed(_ => timeReport.value.tenant?.billing_handle)
    const client_handle = computed(_ => timeReport.value.client?.billing_handle)

    const getCountTicketTasks = ticket => {
      return ticket.tasks.filter(task => timeReport.value.time_tasks.map(tt => tt.id).includes(task.id)).length
    }

    getTimeReport()
      .then(r => r)
      .catch(error => {
        if (error.response.status === 404) {
          $router.push({ name: 'notFound' })
        }
      })

    onBeforeUnmount(_ => {
      clearTicketSummary()
    })

    const showPdf = _ => {
      getTimeReportPdf(timeReport.value.id).then(_ => (visiblePdf.value = true))
    }

    const ticketPanels = ref([])

    const openAllPanels = _ => {
      ticketPanels.value = Object.keys(timeReport.value.time_tasks).map((k, i) => i)
    }

    const closeAllPanels = _ => {
      ticketPanels.value = []
    }

    return {
      prevRoute,
      pending,
      timeReport,
      ticketTaskHeaders,
      tenant_handle,
      client_handle,
      visiblePdf,
      timeReportTicketsWithTasks,
      getCountTicketTasks,
      timeReportPdf,
      ticketPanels,
      openAllPanels,
      closeAllPanels,
      showPdf,
    }
  },
}) //
</script>
